import { Button } from "@fefyi/ui/button";
import { Container } from "@fefyi/ui/container";
import {
  ArrowDown,
  ArrowRight,
  ChevronRight,
  Code,
  LibraryBig,
  MessageCircleCode,
} from "lucide-react";
import { useState, useEffect } from "react";
import { twMerge } from "tailwind-merge";

const sections = ["courses", "projects", "community"] as const;

export const ProSection = ({
  joinButton = "to-pro",
  title,
  text,
  children,
}: {
  joinButton?: "to-pro" | "purchase" | "none";
  title?: string;
  text?: string;
  children: React.ReactNode;
}) => {
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveSection(
        (currentSection) => (currentSection + 1) % sections.length,
      );
    }, 10000);

    return () => clearTimeout(timeout);
  }, [activeSection]);

  return (
    <Container>
      <div className="mb-3 grid grid-cols-1 items-end gap-5 md:mb-6 md:gap-8 xl:grid-cols-[1fr_1.5fr]">
        <div>
          <h2 className="mb-3 max-w-[30ch] text-2xl leading-[1] font-medium text-balance md:text-4xl md:leading-[0.9]">
            {title ? (
              title
            ) : (
              <>
                Frontend.FYI
                <br className="hidden md:block" />
                <span className="text-pro inline-block max-md:ml-1">PRO</span>
              </>
            )}
          </h2>

          {joinButton === "to-pro" && (
            <Button intent="pro" href="/pro">
              Join PRO Today <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          )}
          {joinButton === "purchase" && (
            <Button intent="pro" href="/pro#plans">
              Join Today For €249,- <ArrowDown className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        <div>
          <div className="grid grid-cols-[minmax(0,1fr)_70px] items-end gap-2 sm:grid-cols-3">
            {sections.map((section, index) => (
              <button
                className={twMerge(
                  "border-white-opaque hover:bg-white-opaque focus-visible:bg-white-opaque flex h-full flex-col rounded-2xl border p-3 text-left shadow-2xl transition-colors duration-300 max-sm:w-full md:p-5",
                  activeSection === index
                    ? "bg-white-opaque w-full"
                    : "bg-transparent max-sm:hidden",
                )}
                key={section}
                onClick={() => setActiveSection(index)}
              >
                {index === 0 && (
                  <>
                    <span className="flex items-center gap-2 md:block">
                      <LibraryBig className="h-5 w-5 stroke-1 opacity-90 md:mb-3 md:h-7 md:w-7" />
                      <h3 className="font-medium">Premium courses</h3>
                    </span>
                    <p className="mb-3 text-sm opacity-70">
                      Ever-growing list of premium courses
                    </p>
                  </>
                )}
                {index === 1 && (
                  <>
                    <span className="flex items-center gap-2 md:block">
                      <Code className="h-5 w-5 stroke-1 opacity-90 md:mb-3 md:h-7 md:w-7" />
                      <h3 className="font-medium">Buildable projects</h3>
                    </span>
                    <p className="mb-3 text-sm opacity-70">
                      Don't just watch videos. Build it yourself!
                    </p>
                  </>
                )}
                {index === 2 && (
                  <>
                    <span className="flex items-center gap-2 md:block">
                      <MessageCircleCode className="h-5 w-5 stroke-1 opacity-90 md:mb-3 md:h-7 md:w-7" />
                      <h3 className="font-medium">Community</h3>
                    </span>
                    <p className="mb-3 text-sm opacity-70">
                      Get access to our Discord community
                    </p>
                  </>
                )}
                <div
                  className={twMerge(
                    "bg-white-opaque relative mt-auto h-1 w-full overflow-clip rounded-full",
                    "before:absolute before:inset-0 before:origin-left before:rounded-full before:bg-white before:opacity-0",
                    activeSection === index &&
                      "before:animate-timer before:opacity-100",
                  )}
                />
              </button>
            ))}
            <button
              onClick={() =>
                setActiveSection(
                  (currentSection) => (currentSection + 1) % sections.length,
                )
              }
              className="border-white-opaque bg-white-opaque grid h-full place-items-center rounded-2xl border p-3 sm:hidden"
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
      <a
        href="/pro"
        className={twMerge(
          "border-white-opaque grid h-[300px] grid-cols-1 grid-rows-1 overflow-clip rounded-2xl border *:[grid-area:1/1] md:h-[70vh] md:rounded-[24px]",
          "[&_astro-slot]:grid [&_astro-slot>picture]:[grid-area:1/1] [&_picture]:opacity-0",
          activeSection === 0 && "[&_picture:nth-child(1)]:opacity-100",
          activeSection === 1 && "[&_picture:nth-child(2)]:opacity-100",
          activeSection === 2 && "[&_picture:nth-child(3)]:opacity-100",
        )}
      >
        {children}
      </a>
    </Container>
  );
};
